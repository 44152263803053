import { Notification } from 'element-ui';

function downloadFileInBackground(url, fileName = 'downloaded-file') {
    const loadingNotification = Notification({
        message: `
            <p style="color: rgb(0, 0, 0); font-size: 20px; font-weight: bold;">
                <i class="el-icon-loading" style="margin-right: 10px;"></i> Preparing your file...
            </p>
            <p style="color: rgb(0, 0, 0); font-size: 14px; margin-top: 10px;">
                Please wait while we prepare your file for download.
            </p>
        `,
        duration: 0,
        dangerouslyUseHTMLString: true,
    });

    // Trigger the file download asynchronously
    setTimeout(() => {
        fetch(url, { method: 'GET' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch the file');
                }
                return response.blob();
            })
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);  // Clean up the blob URL

                loadingNotification.close();
                Notification({
                    message: `
                            <p style="color: rgb(0, 0, 0); font-size: 20px; font-weight: bold;">
                                <i class="el-icon-success" style="margin-right: 10px; color: green;"></i> Download Successful
                            </p>
                            <p style="color: rgb(0, 0, 0); font-size: 14px; margin-top: 10px;">
                                Your file "${fileName}" has been downloaded successfully.
                            </p>
                        `,
                    duration: 3000,
                    dangerouslyUseHTMLString: true,
                });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
                loadingNotification.close();
                Notification({
                    message: `
                            <p style="color: rgb(0, 0, 0); font-size: 20px; font-weight: bold;">
                                <i class="el-icon-error" style="margin-right: 10px; color: red;"></i> Download Error
                            </p>
                            <p style="color: rgb(0, 0, 0); font-size: 14px; margin-top: 10px;">
                                There was an error downloading file
                            </p>
                        `,
                    duration: 3000,
                    dangerouslyUseHTMLString: true,
                });
            });
    }, 0);
}

export { downloadFileInBackground }