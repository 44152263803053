<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <h2 style="font-size: 40px; font-weight: 700; line-height: 48px; " class="mb-0 mt-5">Report Analytics</h2>
    </div>
   
    <div class="col-12 d-flex" style="margin: 80px 0px;gap: 12px;">
      <div class="col-6 report-switch-tab" @click="handleChangeTabs('default')" :class="{'--active':selectedModule == 'default'}">Default Report</div>
      <div class="c0l-6 report-switch-tab" @click="handleChangeTabs('agency');"  :class="{'--active':selectedModule == 'agency'}">Agency Report</div>
    </div>


   
    <div class="col-sm-12 col-md-12 col-lg-12" v-show="selectedModule == 'default'">
      <div class="text-left pb-4 d-inline-block" v-if="!$global.globalviewmode">
        <span class="d-block mb-1">Select Company</span>
          <el-select
              class="select-primary"
              size="large"
              placeholder="Select Company"
              filterable
              default-first-option
              v-model="selects.rootSelected"
              style="width:250px"
              @change="viewRootCompany"
          >
          
              <el-option
                  v-for="option in selects.rootList"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
              >
              </el-option>
          </el-select>
    </div>
    <div class="d-inline-block ml-2">
            <div class="d-block mb-1">Select Date</div>
            <div class="d-inline-block">
                <base-input>
                  <el-date-picker
                    type="date"
                    placeholder="Date Start"
                    v-model="reportStartDate"
                    value-format="yyyy-MM-dd"
                    @change="getReportAnalytics()"
                  >
                  </el-date-picker>
                </base-input>        
            </div>
            </div>
            <div class="d-inline-block pl-2 pr-2">-</div>
            <div class="d-inline-block">
                <base-input>
                  <el-date-picker
                    type="date"
                    placeholder="Date End"
                    v-model="reportEndDate"
                    value-format="yyyy-MM-dd"
                    @change="getReportAnalytics()"
                  >
                  </el-date-picker>
                </base-input>        
            </div>
            <div class="d-inline-block pl-4">
            <base-button size="sm" style="height:40px" @click="ExportAnalyticsData()">
              <i class="fas fa-cloud-download-alt pr-2"></i> Download Data
            </base-button>
            <base-button size="sm" style="height:40px; margin-left: 1rem;" @click="ExportFailedLeadRecord()">
              <i class="fas fa-cloud-download-alt pr-2"></i> Download Unserved Leads
            </base-button>
          </div>
          <div><small>* Download Unserved Leads (includes all companies, date filter applied)</small></div>
          
          <div class="pt-3 pb-3">&nbsp;</div>
          <div class="col-sm-12 col-md-12 col-lg-12 pt-3 pb-3" style="margin-right: -15px; margin-left: -15px;">
              <div class="row">
                <skeleton-card-report-analytics v-if="isLoadingReportAnalytics"></skeleton-card-report-analytics>
                <!-- Stats Cards -->
                  <div v-else class="col-lg-3 col-md-6" v-for="card in statsCards.filter(info => info.showCard === true)" :key="card.id">
                    <stats-card
                      :title="card.title"
                      :sub-title="card.subTitle"
                      :type="card.type"
                      :icon="card.icon"
                    >
                      <div slot="footer" v-html="card.footer"></div>
                    </stats-card>
                  </div>
              </div>
           </div>
    </div>

      <!-- Agency Report Analytics table -->
      <div class="col-sm-12 col-md-12 col-lg-12" v-show="selectedModule == 'agency'">
        <div class="container__input__filter__report__analytics">
              <div class="container__input__report__analytics">
                <base-input class='mb-0'>
                  <el-input type="search" class="search-input input__report__analytics" clearable prefix-icon="el-icon-search"
                      placeholder="Search" v-model="searchQuery" aria-controls="datatables" @keyup.native.enter="searchKeyWord" @clear="searchKeyWord">
                  </el-input>
                </base-input>
              </div>
              <div class="container__filter__report__analytics">
                <div v-if="!$global.globalviewmode" class="container__select__company__agency__report">
                  <span class="d-block">Select Company</span>
                    <el-select
                        class="select-primary select__company__agency__report"
                        size="large"
                        placeholder="Select Company"
                        filterable
                        default-first-option
                        v-model="selects.rootSelected"
                        @change="viewRootCompany"
                    >
                    
                        <el-option
                            v-for="option in selects.rootList"
                            class="select-primary"
                            :value="option.id"
                            :label="option.name"
                            :key="option.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="d-inline-block container__date__report__analytics">
                  <div class="d-block">Select Date</div>
                    <base-input class="mb-0"
                    style="width: 100%;"
                    >
                      <el-date-picker
                        type="date"
                        placeholder="Created Date"
                        v-model="agencySelectedDate"
                        value-format="yyyy-MM-dd"
                        @change="getAgencyList('created_at', 'descending')"
                      >
                      </el-date-picker>
                    </base-input>        
                </div>
                <div class="container__button__filter__report__analytics">
                  <el-dropdown ref="dropdown" @visible-change="handleVisibleChange" class="container__button__filter__report__analytics">
                    <span>
                    <base-button class="m-0 button__filter__report__analytics" size="sm" style="height:40px" >
                        <i class="fa-solid fa-filter"></i> Filters
                    </base-button>
                    </span>

                    <el-dropdown-menu slot="dropdown" style="width: 260px;"  :class="{ 'dropdown-hidden': !dropdownVisible }" @click.native.stop>
                    <div class='ml-4 mr-4'>
                        <el-collapse value="Payment Status" style="border: none;">
                        <el-collapse-item title="Payment Status" name="Payment Status">
                            <div style="padding-left:8px;">
                            <el-checkbox v-model="filters.cardStatus.active">Stripe not connected</el-checkbox>
                            <el-checkbox v-model="filters.cardStatus.inactive">Card not connected</el-checkbox>
                            <el-checkbox v-model="filters.cardStatus.failed">Wholesale price not set</el-checkbox>
                            </div>
                        </el-collapse-item>
                        </el-collapse>
                        <el-collapse value="Campaign Status" style="border: none;">
                        <el-collapse-item title="Campaign Status" name="Campaign Status">
                            <div style="padding-left:8px;">
                            <el-checkbox v-model="filters.campaignStatus.active">Active</el-checkbox>
                            <el-checkbox v-model="filters.campaignStatus.inactive">Inactive</el-checkbox>
                            </div>
                        </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class='d-flex justify-content-end mr-4 mt-4'>
                        <base-button @click="applyFilters" :simple="true" size="sm">
                        Save
                        </base-button>
                    </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="container__button__export__data">
                  <base-button class="button__export__data" @click="ExportAgenciesReport()" size="sm" style="height:40px; margin: 0px;">
                    <i class="fas fa-cloud-download-alt pr-2"></i> Export Data
                  </base-button>
                </div>
              </div>
        </div>
        <div class="report-table" v-show="selectedModule == 'agency'">
           <card>
                       <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                           <el-table class="table1" :highlight-current-row="false" :data="queriedData" @sort-change="sortdynamic"
                               ref="tableData" :default-sort="{prop: 'agency_name', order: 'ascending'}"
                               style="width: 525px"
                               >
                               
                               <template #empty>
                                   <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                               </template>
                               
                               <el-table-column fixed prop="agency_name" min-width="200"  sortable="custom"
                                   label="Agency Name" align="left">
                                   <div slot-scope="props">
                                       {{ props.row.agency_name }}
                                   </div>
                               </el-table-column>
                               <el-table-column prop="client_name" min-width="200" sortable="custom" align="center"
                                   label="Client Name">
                                   <div slot-scope="props">{{ props.row.client_name }}</div>
                               </el-table-column>
                               <!--<el-table-column  min-width="15%"  label="E-mail">
                                       <div slot-scope="props">
                                             {{props.row.email}}
                                       </div>
                                   </el-table-column>-->
                               <!--<el-table-column  min-width="205" sortable :sort-method="sortcolumn" label="Report Type">
                                       <div slot-scope="props" v-html="setIconReportType(props.row)">
                                           
                                       </div>
                                   </el-table-column>-->
                               <el-table-column prop="agency_email" min-width="250"  align="center"
                                   label="Email">
                                   <div slot-scope="props">
                                       {{ props.row.agency_email != null ? props.row.agency_email : '-' }}
                                   </div>
                               </el-table-column>
                               <el-table-column prop="agency_phone"  min-width="175"  align="center" label="Phone">
                                       <div slot-scope="props">
                                            <span>{{props.row.agency_phone}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="clients_created"  min-width="175"   align="center" label="Clients Created">
                                       <div slot-scope="props">
                                            <span>{{props.row.clients_created}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="under_perform_campaigns_total"  min-width="250"   align="center" label="Under performing campaigns">
                                       <div slot-scope="props">
                                            <div style="cursor: pointer;" @click.stop="showModalsUnderPerform(props.row.under_perform_campaigns_details)">{{props.row.under_perform_campaigns_total}}</div>
                                       </div>
                               </el-table-column>
                               <el-table-column min-width="200"
                                    align="left" label="Connected Status">
                                   <div slot-scope="props">
                                    <div class="d-flex flex-column" style="gap: 22px;" >
                                      <div class="d-flex" style="gap: 32px;">

                                        <el-tooltip
                                          v-if="props.row.status_credit_card"
                                          :content="creditCardTooltip(props.row.status_credit_card)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="far fa-credit-card connected-status-item"
                                            :style="{ color: creditCardColor(props.row.status_credit_card) }"
                                          ></i>
                                        </el-tooltip>

                                        <el-tooltip
                                          v-if="props.row.status_stripe"
                                          :content="stripeTooltip(props.row.status_stripe)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-brands fa-stripe-s connected-status-item"
                                            :style="{ color: stripeColor(props.row.status_stripe) }"
                                          ></i>
                                        </el-tooltip>

                                        <el-tooltip
                                          v-if="props.row.status_google != null"
                                          :content="googleTooltip(props.row.status_google)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-brands fa-google-drive connected-status-item"
                                            :style="{ color: googleColor(props.row.status_google) }"
                                          ></i>
                                        </el-tooltip>

                                        <el-tooltip
                                          v-if="props.row.status_manual_bill != null"
                                          :content="manualBillTooltip(props.row.status_manual_bill)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-solid fa-cash-register connected-status-item"
                                            :style="{ color: manualBillColor(props.row.status_manual_bill) }"
                                          ></i>
                                        </el-tooltip>

                                      </div>
                                      <div class="d-flex" style="gap: 32px;">

                                        <el-tooltip
                                          v-if="props.row.status_domain != null"
                                          :content="domainTooltip(props.row.status_domain)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-solid fa-link connected-status-item"
                                            :style="{ color: domainColor(props.row.status_domain) }"
                                          ></i>
                                        </el-tooltip>

                                        <el-tooltip
                                          v-if="props.row.status_logo != null"
                                          :content="logoTooltip(props.row.status_logo)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-regular fa-image connected-status-item"
                                            :style="{ color: logoColor(props.row.status_logo) }"
                                          ></i>
                                        </el-tooltip>

                                        <el-tooltip
                                          v-if="props.row.status_smtp != null"
                                          :content="smtpTooltip(props.row.status_smtp)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-regular fa-envelope connected-status-item"
                                            :style="{ color: smtpColor(props.row.status_smtp) }"
                                          ></i>
                                        </el-tooltip>
                                        
                                        <el-tooltip
                                          v-if="props.row.status_default_price != null"
                                          :content="defaultPriceTooltip(props.row.status_default_price)"
                                          effect="light"
                                          :open-delay="300"
                                          placement="top"
                                        >
                                          <i
                                            class="fa-regular fa-dollar-sign connected-status-item"
                                            :style="{ color: defaultPriceColor(props.row.status_default_price) }"
                                          ></i>
                                        </el-tooltip>
                                      </div>
                                    </div>
                                   </div>
                               </el-table-column>
                               <el-table-column prop="clients_active_credit_card"  min-width="175"  align="center" label="Clients with Active Credit Card">
                                       <div slot-scope="props">
                                            <span>{{props.row.clients_active_credit_card}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="recently_start_campaign"  min-width="140" align="center" label="Active Campaigns">
                                       <div slot-scope="props">
                                            <span>{{props.row.recently_start_campaign}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="local_active_campaign"  min-width="140" align="center" label="Site Campaigns Active">
                                       <div slot-scope="props">
                                            <span>{{props.row.local_active_campaign}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="locator_active_campaign"  min-width="170"  align="center" label="Search Campaigns Active">
                                       <div slot-scope="props">
                                            <span>{{props.row.locator_active_campaign}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="enhance_active_campaign"  min-width="180" align="center" label="Enhance Campaigns Active">
                                       <div slot-scope="props">
                                            <span>{{props.row.enhance_active_campaign}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="total_stopped_campaign"  min-width="180" align="center" label="Stop Campaigns">
                                       <div slot-scope="props">
                                            <span>{{props.row.total_stopped_campaign}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="weekly_revenue"  min-width="180" align="center" label="Total Weekly Revenue">
                                       <div slot-scope="props">
                                            <span>$ {{props.row.weekly_revenue}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="minspend_start_date"  min-width="175"  align="center" label="Min Spend Start">
                                       <div slot-scope="props">
                                            <span>{{props.row.minspend_start_date}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="account_executive"  min-width="180"   align="center" label="Account Executive">
                                       <div slot-scope="props">
                                            <span>{{props.row.account_executive != null ? props.row.account_executive : '-'}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="account_referral"  min-width="175"  align="center" label="Referral Agent">
                                       <div slot-scope="props">
                                            <span>{{props.row.account_referral != null ? props.row.account_referral : '-'}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="sales_representative"  min-width="175"  align="center" label="Sales Agent">
                                       <div slot-scope="props">
                                            <span>{{props.row.sales_representative != null ? props.row.sales_representative : '-'}}</span>
                                       </div>
                               </el-table-column>
                              
                               <el-table-column prop="created_on"  min-width="175" sortable="custom"  align="center" label="Created On">
                                       <div slot-scope="props">
                                            <span> {{props.row.created_on}}</span>
                                       </div>
                               </el-table-column>
                           </el-table>
                       </div>
           <template #footer>
              <div class="float-right">
                <div class="pt-3">
                      <p class="card-category">
                          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }}
                          entries
                      </p>
                </div>
  
                <base-pagination
                 class="pagination-no-border pt-4"
                 v-model="pagination.currentPage"
                 :per-page="pagination.perPage"
                 :total="pagination.total"
                 @input="changePage"
                 >
                </base-pagination>
                      
              </div>
           </template>
        </card>
      </div>
    </div>
    <modal v-if="selectedModule == 'agency'" :show.sync="isCampaignModelOpen" bodyClasses='create-campaign-modal-body under-performing-campaigns'
            footerClasses="border-top p-2">
                <h3 slot="header" class="title title-up">Under Performing campaigns of dummy agency</h3>
                <div class="row">
                  <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table mt-5 mb-2">
                           <el-table class="table2" :highlight-current-row="false" :data="campaignsData"
                               ref="tableDataUnderPerformCampaign"
                               height="250"
                               style="width: 525px;"
                               >
                               
                               <!-- <template #empty>
                                   <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                               </template> -->
                               
                               <el-table-column fixed prop="company_name" min-width="200"  sortable="custom"
                                   label="Company Name" align="left">
                                   <div slot-scope="props">
                                       {{ props.row.company_name }}
                                   </div>
                               </el-table-column>
                               <el-table-column prop="campaign_name" min-width="200" sortable="custom" align="center"
                                   label="Campaign Name">
                                   <div slot-scope="props">{{ props.row.campaign_name }}</div>
                               </el-table-column>
                              
                               <el-table-column prop="leadspeek_api_id" min-width="250"  align="center"
                                   label="Campaign ID">
                                   <div slot-scope="props">
                                       {{ props.row.leadspeek_api_id }}
                                   </div>
                               </el-table-column>
                               <el-table-column prop="total_leads"  min-width="175"  align="center" label="Total leads">
                                       <div slot-scope="props">
                                            <span>{{props.row.total_leads}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="lp_limit_leads"  min-width="175"   align="center" label="Requested Leads">
                                       <div slot-scope="props">
                                            <span>{{props.row.lp_limit_leads}}</span>
                                       </div>
                               </el-table-column>
                               <el-table-column prop="created_on"  min-width="250"   align="center" label="Created at">
                                       <div slot-scope="props">
                                            <div>{{props.row.created_on}}</div>
                                       </div>
                               </el-table-column>
                               
                           </el-table>
                       </div>
                </div>
    </modal>
  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import SkeletonCardReportAnalytics from 'src/components/Pages/ReportAnalytics/SkeletonCardReportAnalytics'
import { DatePicker, Select, Option,Checkbox,Table, TableColumn, Dropdown, DropdownMenu, DropdownItem,CollapseItem,Collapse} from 'element-ui';
import { BasePagination,Modal } from 'src/components';
import { downloadFileInBackground } from '../../../../helpers/downloadFile'

export default {
    components: {
        [DatePicker.name]: DatePicker,
        StatsCard,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BasePagination,
        Modal,
        SkeletonCardReportAnalytics,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Collapse.name]: Collapse,
        [Checkbox.name]: Checkbox,
        [CollapseItem.name]: CollapseItem,
    },
    computed:{
      queriedData(){
        let result = this.tableData;
        if (this.searchedData.length > 0) {
            result = this.searchedData;
        }
        return result
      },
      to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
    },
    data() {
        return {
          dropdownVisible: false,
          filters:{
                cardStatus:{
                    show:false,
                    active:false,
                    failed:false,
                    inactive:false
                },
                campaignStatus:{
                    show:false,
                    active:false,
                    inactive:false,
                }
            },
          campaignsData:[],
          selectedModule:'default',
          isCampaignModelOpen: false,
          pagination: {
                perPage: 10,
                currentPage: 1,
                //perPageOptions: [5, 10, 25, 50],
                total: 0,
                from: 0,
                to: 0,
            },
            
            agencySelectedDate: '',
            reportStartDate: '',
            reportEndDate: '',
            activeCompanyID: '',
            activeCampaignID: '',
            selects: {
              rootList: [],
              rootSelected: "",
            },
            statsCards: [
                {
                    id: '0',
                    title: '0 Fire(s)',
                    subTitle: 'Total Fires',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Tower Data Pixel Fire',
                    showCard:true,
                },
                {
                    id: '1',
                    title: '0 Call(s)',
                    subTitle: 'Total Calls',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful Tower Data Postal API',
                    showCard:true,
                },
                {
                    id: '2',
                    title: '0 Call(s)',
                    subTitle: 'Total Calls',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful BIG BDM MD5',
                    showCard:true,
                },
                    
                {
                    id: '3',
                    title: '0 Call(s)',
                    subTitle: 'Total Calls',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful BIG BDM PII',
                    showCard:true,
                },
                {
                    id: '4',
                    title: '0 Serve(s)',
                    subTitle: 'Total Serves',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful Site ID serve data',
                    showCard:true,
                },
                {
                    id: '5',
                    title: '$0',
                    subTitle: 'Total Revenue',
                    type: 'primary',
                    icon: 'fas fa-dollar-sign',
                    footer: 'Site ID serve revenue',
                    showCard:true,
                },
                {
                    id: '6',
                    title: '0 Serve(s)',
                    subTitle: 'Total Serves',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful Search ID serve data',
                    showCard:true,
                },
                {
                    id: '7',
                    title: '$0',
                    subTitle: 'Total Revenue',
                    type: 'primary',
                    icon: 'fas fa-dollar-sign',
                    footer: 'Search ID serve revenue',
                    showCard:true,
                },
                {
                    id: '8',
                    title: '0 Blocked',
                    subTitle: 'Total Blocked',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'Blocked by Zero Bounce',
                    showCard:true,
                },
                {
                    id: '9',
                    title: '0 Blocked',
                    subTitle: 'Total Blocked',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'Blocked by Location Lock',
                    showCard:true,
                },
                {
                    id: '10',
                    title: '0 Active',
                    subTitle: 'Total Active',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Site ID active campaign',
                    showCard:true,
                },
                {
                    id: '11',
                    title: '0 Active',
                    subTitle: 'Total Active',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Search ID active campaign',
                    showCard:true,
                },
                {
                    id: '12',
                    title: '0 Serve(s)',
                    subTitle: 'Total Serves',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Successful Enhance ID serve data',
                    showCard:true,
                },
                {
                    id: '13',
                    title: '$0',
                    subTitle: 'Total Revenue',
                    type: 'primary',
                    icon: 'fas fa-dollar-sign',
                    footer: 'Enhance ID serve revenue',
                    showCard:true,
                },
                {
                    id: '14',
                    title: '0 Active',
                    subTitle: 'Total Active',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'Enhance ID active campaign',
                    showCard:true,
                },
                {
                    id: '15',
                    title: '0 Lead(s)',
                    subTitle: 'Total Leads',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'BIG BDM MD5 Requested',
                    showCard:true,
                },
                {
                    id: '16',
                    title: '0 Lead(s)',
                    subTitle: 'Total Leads',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'BIG BDM MD5 Delivered',
                    showCard:true,
                },
                {
                    id: '17',
                    title: '0 Lead(s)',
                    subTitle: 'Total Remaining',
                    type: 'primary',
                    icon: 'fas fa-sort-amount-up',
                    footer: 'BIG BDM MD5 REMAINING LEADS',
                    showCard:true,
                },
                {
                    id: '18',
                    title: '0 Failed',
                    subTitle: 'Total Failed',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'ThirdParty Failed Get Data',
                    showCard:true,
                },
                {
                    id: '19',
                    title: '0 Failed',
                    subTitle: 'Total Failed',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'Failed Get Data BIG BDM MD5',
                    showCard:false,
                },
                {
                    id: '20',
                    title: '0 Failed',
                    subTitle: 'Total Failed',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'Failed Get Data TOWER DATA',
                    showCard:false,
                },
                {
                    id: '21',
                    title: '0 Failed',
                    subTitle: 'Total Failed',
                    type: 'primary',
                    icon: 'fas fas fa-do-not-enter',
                    footer: 'Failed Get Data BIG DBM PII',
                    showCard:false,
                },
            ],
          tableDataOri:[],
          tableData: [],
          searchedData: [],
          searchQuery: '',
          currSortBy: '',
          currOrderBy: '',
          isLoadingReportAnalytics: true,
          tableDataUnderPerformCampaign:[],
        };
    },
    methods: {
      handleChangeTabs(tab){
        this.selectedModule = tab;
        if(tab == 'agency'){
          this.getAgencyList('created_at', 'descending')
        } else {
          this.getReportAnalytics()
        }
      },
      showModalsUnderPerform(campaignIds){
        this.getUndePerformCampaign(campaignIds)
        this.isCampaignModelOpen = true;
      },
      creditCardTooltip(status) {
        switch (status) {
          case "active":
            return "Credit Card Connected";
          case "inactive":
            return "Credit Card Inactive";
          case "failed":
            return "Credit Card Failed";
          default:
            return "No Credit Card Status";
        }
      },
      creditCardColor(status) {
        switch (status) {
          case "active":
            return "green";
          case "inactive":
            return "gray";
          case "failed":
            return "red";
          default:
            return "gray";
        }
      },
      stripeTooltip(status) {
        switch (status) {
          case "completed":
            return "Stripe Connected";
          case "inverification":
            return "Agency in Verification";
          case "pending":
            return "Agency need to complete the registration";
          case "not_registered":
            return "Agency Not Registered";
          default:
            return "No Credit Card Status";
        }
      },
      stripeColor(status) {
        switch (status) {
          case "completed":
            return "green";
          case "inverification":
            return "orange";
          case "pending":
            return "yellow";
          case "not_registered":
            return "gray";
          case "error_check_status":
            return "red";
          default:
            return "gray";
        }
      },
      googleTooltip(status) {
        switch (status) {
          case true:
            return "Google Connected";
          case false:
            return "Not Connected";
          default:
            return "Not Connected";
        }
      },
      googleColor(status) {
        switch (status) {
          case true:
            return "green";
          case false:
            return "gray";
          default:
            return "gray";
        }
      },
      manualBillTooltip(status) {
        switch (status) {
          case true:
            return "Wholesale pricing saved (or Direct Pay Enabled)";
          case false:
            return "Wholesale pricing not saved (or Direct Pay Disabled)";
          default:
            return "Wholesale pricing not saved (or Direct Pay Disabled)";
        }
      },
      manualBillColor(status) {
        switch (status) {
          case true:
            return "yellow";
          case false:
            return "gray";
          default:
            return "gray";
        }
      },
      domainTooltip(status) {
        switch (status) {
          case true:
            return "URL Customized";
          case false:
            return "URL not set";
          default:
            return "URL not set";
        }
      },
      domainColor(status) {
        switch (status) {
          case true:
            return "green";
          case false:
            return "gray";
          default:
            return "gray";
        }
      },
      logoTooltip(status) {
        switch (status) {
          case true:
            return "Logo Updated";
          case false:
            return "Logo not set";
          default:
            return "Logo not set";
        }
      },
      logoColor(status) {
        switch (status) {
          case true:
            return "green";
          case false:
            return "gray";
          default:
            return "gray";
        }
      },
      smtpTooltip(status) {
        switch (status) {
          case true:
            return "SMTP Established";
          case false:
            return "SMTP not Established";
          default:
            return "SMTP not Established";
        }
      },
      smtpColor(status) {
        switch (status) {
          case true:
            return "green";
          case false:
            return "gray";
          default:
            return "gray";
        }
      },
      defaultPriceTooltip(status) {
        switch (status) {
          case true:
            return "Default price set";
          case false:
            return "Default price not set";
          default:
            return "Default price not set";
        }
      },
      defaultPriceColor(status) {
        switch (status) {
          case true:
            return "green";
          case false:
            return "red";
          default:
            return "red";
        }
      },
      getUndePerformCampaign(campaignIds){
        this.campaignsData = [];
        $('.table2 .el-table__empty-text').html('<i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...');
        this.$store.dispatch('GetUnderPerformCampaigns', {
             campaignIds: campaignIds,
             selectedDate: this.agencySelectedDate,
            }).then(response => {
              if (response.campaigns.length > 0) {
                this.campaignsData = response.campaigns
              } else {
                $('.table2 .el-table__empty-text').html('No Data');
              }
            },error => {
              this.campaignsData = [];
              $('.table2 .el-table__empty-text').html('No Data');
            });

      },
      getAgencyList(sortby,order){
            var _sortby = '';
            var _order = '';
            var _searchkey = '';
            
            if (typeof(sortby) != 'undefined') {
                _sortby = sortby;
            }
            if (typeof(order) != 'undefined') {
                _order = order;
            }

            if (this.searchQuery != '') {
                _searchkey = this.searchQuery;
            }            

            /** GET AGENCY LIST */
            this.tableData = [];
            $('.table1 .el-table__empty-text').html('<i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...');
            this.$store.dispatch('GetAgencyList', {
                groupCompanyID: this.selects.rootSelected,
                sortby: _sortby,
                order: _order,
                searchkey: _searchkey,
                selectedDate: this.agencySelectedDate,
                page:this.pagination.currentPage,
                cardStatus: this.filters.cardStatus,
                campaignStatus: this.filters.campaignStatus,
            }).then(response => {
                this.pagination.currentPage = response.agencies.current_page? response.agencies.current_page : 1
                this.pagination.total = response.agencies.total ?response.agencies.total : 0
                this.pagination.lastPage = response.agencies.last_page ? response.agencies.last_page : 0
                this.pagination.from = response.agencies.from ? response.agencies.from : 0
                this.pagination.to = response.agencies.to ? response.agencies.to : 0
                
                
                
                for(let i=0;i<response.agencies.data.length;i++) {
                    if (response.agencies.data[i].agency_phone == '' || response.agencies.data[i].agency_phone == null) {
                      response.agencies.data[i].agency_phone = '000-000-0000';
                    }
                }
                this.tableData = response.agencies.data

                if (this.tableData.length == 0) {
                    $('.table1 .el-table__empty-text').html('No Data');
                }
            },error => {
              this.tableData = [];
              $('.table1 .el-table__empty-text').html('No Data');
            });
            /** GET AGENCY LIST */
      },
      sortdynamic(column,prop,order) { 
            this.currSortBy = column.prop;
            this.currOrderBy = column.order;
            this.getAgencyList(this.currSortBy,this.currOrderBy);
      },
      searchKeyWord() {
            this.pagination.currentPage = 1;
            this.getAgencyList(this.currSortBy,this.currOrderBy);
      },
      applyFilters(event){
            event.stopPropagation(); // Prevent click propagation
            this.getAgencyList(this.currSortBy, this.currOrderBy)
            this.dropdownVisible = false;
      },
      changePage(event){
            //console.log(this.pagination,event)
            this.getAgencyList(this.currSortBy, this.currOrderBy)
      },
      handleVisibleChange(visible) {            
            this.dropdownVisible = visible;
        },
        ExportAnalyticsData() {
          var reportStart = this.reportStartDate;
          var reportEnd = this.reportEndDate;
          var _companyid = '';
          var _campaignid = '';

          if (this.activeCompanyID != '') {
              _companyid = '/' + this.activeCompanyID;
          }

          if (this.activeCampaignID != '' && _companyid != '') {
              _campaignid = '/' + this.activeCampaignID;
          }

          const url = process.env.VUE_APP_DATASERVER_URL + '/configuration/report-analytics/download/' + reportStart + '/' + reportEnd + _companyid + _campaignid;
          downloadFileInBackground(url, 'report-analytics.csv')
        },
        ExportFailedLeadRecord() {
          var reportStart = this.reportStartDate;
          var reportEnd = this.reportEndDate;

          const url = `${process.env.VUE_APP_DATASERVER_URL}/configuration/failed-lead-record/download/${reportStart}/${reportEnd}`;
          downloadFileInBackground(url, 'unserved-leads.csv')
        },
        ExportAgenciesReport() {
          let CompanyRootId = this.selects.rootSelected;
          let agencyStatus = this.filters.cardStatus; // Misalnya objek { active: false, inactive: false, show: false }
          let campaignStatus = this.filters.campaignStatus; 
          let selectedDate = this.agencySelectedDate;

          // Convert objek ke JSON string
          let agencyStatusString = JSON.stringify(agencyStatus); 
          let campaignStatusString = JSON.stringify(campaignStatus);

          let _groupCompanyID = "/all";
          let _sortby = "";
          let _order = "";
          let _searchkey = "";

          if (CompanyRootId != "" && typeof CompanyRootId != "undefined") {
              _groupCompanyID = "/" + CompanyRootId;
          }

          if (typeof this.currSortBy != "undefined" && this.currSortBy != "") {
              _sortby = "/" + this.currSortBy;
          }

          if (typeof this.currOrderBy != "undefined" && this.currOrderBy != "") {
              if (_sortby != "") {
                  _order = "/" + this.currOrderBy;
              }
          }

          if (typeof this.searchQuery != "undefined" && this.searchQuery != "") {
              if (_sortby == "") {
                  _searchkey = "/created_at/descending/" + this.searchQuery;
              } else {
                  _searchkey = "/" + this.searchQuery;
              }
            }

          const url = `${process.env.VUE_APP_DATASERVER_URL}/configuration/agencies-report/download`
              + _groupCompanyID
              + _sortby
              + _order
              + _searchkey
              + `?AgencyStatus=${encodeURIComponent(agencyStatusString)}&CampaignStatus=${encodeURIComponent(campaignStatusString)}&SelectedDate=${selectedDate}`;

          downloadFileInBackground(url, 'agencies-report.csv')
      },
        viewRootCompany() {
          if(this.selectedModule == 'agency'){
            this.getAgencyList('created_at', 'descending')
          } else {
            this.getReportAnalytics();
          }
        },
        getRootList() {
          this.$store.dispatch('getRootList').then(response => {
            if (this.selects.rootList.length == 0) {
              this.selects.rootList = response.params
              this.selects.rootList.unshift({'id':'','name':'View All'});
              this.selects.rootSelected = this.$global.idsys;
              this.getReportAnalytics();
            }
          },error => {
              
          });
        },
        resetCardValue() {
          this.statsCards[0].title = '0 Fire(s)';
          this.statsCards[1].title = '0 Call(s)';
          //this.statsCards[2].title = total_endato + ' Call(s)';
          //this.statsCards[3].title = total_md5 + ' Call(s)';
          this.statsCards[2].title = '0 Call(s)';
          this.statsCards[3].title = '0 Call(s)';
          this.statsCards[4].title = '0 Serve(s)';
          this.statsCards[5].title = '$0';
          this.statsCards[6].title = '0 Serve(s)';
          this.statsCards[7].title = '$0';
          this.statsCards[8].title = '0 Blocked';
          this.statsCards[9].title = '0 Blocked';
          this.statsCards[10].title = '0 Active';
          this.statsCards[11].title = '0 Active';
          this.statsCards[12].title = '0 Serve(s)';
          this.statsCards[13].title = '$0';
          this.statsCards[14].title = '0 Active';
          this.statsCards[15].title = '0 Lead(s)';
          this.statsCards[16].title = '0 Lead(s)';
          this.statsCards[17].title = '0 Lead(s)';
          this.statsCards[18].title = '0 Failed';
          this.statsCards[19].title = '0 Failed';
          this.statsCards[20].title = '0 Failed';
          this.statsCards[21].title = '0 Failed';
        },
        getReportAnalytics() {
            this.isLoadingReportAnalytics = true;
            this.resetCardValue();
            this.$store.dispatch('getReportAnalytics', {
                startDate: this.reportStartDate,
                endDate: this.reportEndDate,
                companyid: this.activeCompanyID,
                campaignid: this.activeCampaignID,
                companyrootid: this.selects.rootSelected,
            }).then(response => {
              if (response.data.length > 0) {
                var total_pixelfire = 0;
                var total_postal = 0;
                var total_endato = 0;
                var total_md5 = 0;
                var total_bigbdm_md5 = 0;
                var total_bigbdm_pii = 0;
                var total_zerobouncefailed = 0;
                var total_locationlockfailed = 0;
                var total_bigbdm_hems = 0;
                var total_bigbdm_total_leads = 0;
                var total_bigbdm_remaining_leads = 0;
                var total_failed_get_data_all = 0;
                var total_failed_get_data_bigbdmmd5 = 0;
                var total_failed_get_data_gettowerdata = 0;
                var total_failed_get_data_bigbdmpii = 0;

                var total_siteid_serve = 0;
                var total_searchid_serve = 0;
                var total_enhanceid_serve = 0;
                var total_platformfee_siteid_serve = 0;
                var total_platformfee_searchid_serve = 0;
                var total_platformfee_enhanceid_serve = 0;
                
                var total_siteid_active = 0;
                var total_searchid_active = 0;
                var total_enhanceid_active = 0;

                for(var i=0;i<response.data.length;i++) {
                  total_pixelfire = parseFloat(total_pixelfire) + parseFloat(response.data[i].pixelfire);
                  total_postal = parseFloat(total_postal) + parseFloat(response.data[i].towerpostal);
                  total_endato = parseFloat(total_endato) + parseFloat(response.data[i].endatoenrichment);
                  total_md5 = parseFloat(total_md5) +  parseFloat(response.data[i].toweremail);
                  total_bigbdm_md5 = parseFloat(total_bigbdm_md5) + parseFloat(response.data[i].bigbdmemail);
                  total_bigbdm_pii = parseFloat(total_bigbdm_pii) + parseFloat(response.data[i].bigbdmpii);
                  total_zerobouncefailed = parseFloat(total_zerobouncefailed) + parseFloat(response.data[i].zerobouncefailed);
                  total_locationlockfailed = parseFloat(total_locationlockfailed) + parseFloat(response.data[i].locationlockfailed);

                  total_bigbdm_hems = parseFloat(total_bigbdm_hems) + parseFloat(response.data[i].bigbdmhems);
                  total_bigbdm_total_leads = parseFloat(total_bigbdm_total_leads) + parseFloat(response.data[i].bigbdmtotalleads);
                  total_bigbdm_remaining_leads = parseFloat(total_bigbdm_remaining_leads) + parseFloat(response.data[i].bigbdmremainingleads);

                  total_failed_get_data_all = parseFloat(total_failed_get_data_all) + parseFloat(response.data[i].getleadfailed);
                  total_failed_get_data_bigbdmmd5 = parseFloat(total_failed_get_data_bigbdmmd5) + parseFloat(response.data[i].getleadfailed_bigbdmmd5);
                  total_failed_get_data_gettowerdata = parseFloat(total_failed_get_data_gettowerdata) + parseFloat(response.data[i].getleadfailed_gettowerdata);
                  total_failed_get_data_bigbdmpii = parseFloat(total_failed_get_data_bigbdmpii) + parseFloat(response.data[i].getleadfailed_bigbdmpii);

                  if (response.data[i].leadspeek_type == 'local') {
                     total_siteid_serve = response.data[i].serveclient;
                     total_platformfee_siteid_serve = response.data[i].platformfee;
                     total_siteid_active = response.data[i].activecampaign;
                  }else if (response.data[i].leadspeek_type == 'locator') {
                     total_searchid_serve = response.data[i].serveclient;
                     total_platformfee_searchid_serve = response.data[i].platformfee;
                     total_searchid_active = response.data[i].activecampaign;
                  }else if(response.data[i].leadspeek_type == 'enhance') {
                    total_enhanceid_serve = response.data[i].serveclient;
                    total_platformfee_enhanceid_serve = response.data[i].platformfee;
                    total_enhanceid_active = response.data[i].activecampaign;
                  }
                }

                if (this.activeCampaignID != '') {
                    if (response.data[0].leadspeek_type == 'local') {
                        this.statsCards[4].showCard = true;
                        this.statsCards[5].showCard = true;
                        this.statsCards[6].showCard = false;
                        this.statsCards[7].showCard = false;
                        this.statsCards[9].showCard = false;
                    }else if (response.data[0].leadspeek_type == 'locator') {
                        this.statsCards[4].showCard = false;
                        this.statsCards[5].showCard = false;
                        this.statsCards[6].showCard = true;
                        this.statsCards[7].showCard = true;
                        this.statsCards[9].showCard = true;
                    }
                }

                this.statsCards[0].title = total_pixelfire + ' Fire(s)';
                this.statsCards[1].title = total_postal + ' Call(s)';
                //this.statsCards[2].title = total_endato + ' Call(s)';
                //this.statsCards[3].title = total_md5 + ' Call(s)';
                this.statsCards[2].title = total_bigbdm_md5 + ' Call(s)';
                this.statsCards[3].title = total_bigbdm_pii + ' Call(s)';
                this.statsCards[4].title = total_siteid_serve + ' Serve(s)';
                this.statsCards[5].title = '$' + total_platformfee_siteid_serve;
                this.statsCards[6].title = total_searchid_serve + ' Serve(s)';
                this.statsCards[7].title = '$' + total_platformfee_searchid_serve;
                this.statsCards[8].title = total_zerobouncefailed + ' Blocked';
                this.statsCards[9].title = total_locationlockfailed + ' Blocked';
                this.statsCards[10].title = total_siteid_active + ' Active';
                this.statsCards[11].title = total_searchid_active + ' Active';
                this.statsCards[12].title = total_enhanceid_serve + ' Serve(s)';
                this.statsCards[13].title = '$' + total_platformfee_enhanceid_serve;
                this.statsCards[14].title = total_enhanceid_active + ' Active';
                this.statsCards[15].title = total_bigbdm_hems + ' Lead(s)';
                this.statsCards[16].title = total_bigbdm_total_leads + ' Lead(s)';
                this.statsCards[17].title = total_bigbdm_remaining_leads + ' Lead(s)';
                this.statsCards[18].title = total_failed_get_data_all + ' Failed';
                this.statsCards[19].title = total_failed_get_data_bigbdmmd5 + ' Failed';
                this.statsCards[20].title = total_failed_get_data_gettowerdata + ' Failed';
                this.statsCards[21].title = total_failed_get_data_bigbdmpii + ' Failed';
              }
              this.isLoadingReportAnalytics = false;
            },error => {
              this.isLoadingReportAnalytics = false;
            });
        },
        currentDate() {
          const current = new Date();
          var _month = current.getMonth()+1;
          var _year = current.getFullYear();
          var _date = current.getDate();

          _month = ('0' + _month).slice(-2);
          _date = ('0' + _date).slice(-2);

          const date = `${current.getFullYear()}-${_month}-${_date}`;
          return date;
        },
    },
    mounted() {
        const userData = this.$store.getters.userData;
        if (this.$global.globalviewmode && typeof(this.$route.query.campaignid) != 'undefined') {
          this.activeCompanyID = userData.company_id;
          this.activeCampaignID = this.$route.query.campaignid;
          this.statsCards[4].showCard = false;
          this.statsCards[5].showCard = false;
          this.statsCards[6].showCard = false;
          this.statsCards[7].showCard = false;
          
          this.statsCards[8].showCard = false;
          this.statsCards[9].showCard = false;
          this.statsCards[10].showCard = false;
          this.statsCards[11].showCard = false;
          
        }else if (this.$global.globalviewmode && typeof(this.$route.query.campaignid) == 'undefined') {
          this.activeCompanyID = userData.company_id;
          this.activeCampaignID = "";
        }else{
          this.activeCompanyID = "";
          this.activeCampaignID = "";
        }
        this.reportStartDate = this.currentDate();
        this.reportEndDate = this.currentDate();
        this.agencySelectedDate = this.currentDate();
        this.getRootList();
        //this.getReportAnalytics();
    },
};

</script>
<style>
.report-table .el-table__fixed{
  background: var(--background-color-secondary);
}
.report-table .el-table__fixed-right::before, .el-table__fixed::before {
  background-color: var(--background-color-secondary);
}
.report-table .el-table__body tr.hover-row>td {
  background-color: var(--table-hover-color);
}
.report-switch-tab{
  display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 25px;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    flex-grow: 1;
    border: 1px solid var(--accent-color);
   
}
.report-switch-tab.--active {
  background: var(--btn-primary-color);
  color: var(--text-btn-black);
}
.under-performing-campaigns .el-table table {
color: black !important;
}
.under-performing-campaigns .el-table__fixed{
  background: #ffffff;
}

.container__input__report__analytics {
  width: 30%;
}

.container__input__filter__report__analytics {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  gap: 16px;
}

.container__filter__report__analytics {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.input__report__analytics .el-input__inner {
    padding-left: 30px;
    padding-right: 30px;
}

.select__company__agency__report .el-input__inner {
  border-radius: 0.4285rem;
}

@media (max-width: 992px) {
  .container__input__report__analytics {
    width: 100%;
  }
  .container__input__filter__report__analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container__filter__report__analytics {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .container__button__filter__report__analytics {
    width: 100%;
  }
  .button__filter__report__analytics {
    width: 100%;
  }
  .container__date__report__analytics {
    width: 100%;
  }
  .container__button__export__data {
    width: 100%;
  }
  .button__export__data {
    width: 100%;
  }
  .container__select__company__agency__report {
    width: 100%;
  }
  .select__company__agency__report {
    width: 100%;
  }
}
</style>