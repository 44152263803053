<template>
    <div>
        <label class="mb-0">Tags Automation</label>
        <div v-if="isLoadingTagsAutomation" class="container__tags__automation">
            <el-card shadow="never">
                <SkeletonLoader v-if="isLoadingTagsAutomation" :rows="3" :isShort="false" />
            </el-card>
        </div>
        <div v-else-if="isErrorTagsAutomation" class="error-banner">
            There is an issue with the tags automation. Please refresh or try again later.
        </div>
        <div v-else class="container__tags__automation">
            <el-card shadow="hover"
                style="position: relative; cursor: pointer;"
                :class="isActiveTagsAutomation ? 'enabled__class__tags__automation' : ''"
                @click.native="onTagsAutomation"
            >
                <div style="display: flex; justify-content: center;">
                    <i class="fas fa-tags" style="font-size: 24px;" :style="{color: isActiveTagsAutomation  ? '#409eff !important' : 'gray !important'}"></i>
                </div>
                <p style="margin-bottom: 0px; text-align: center; font-weight: 600; margin-top: 8px;" :style="{color: isActiveTagsAutomation  ? '#409eff !important' : 'gray !important'}">Keywords to Tags {{isActiveTagsAutomation ? 'Enabled' : 'Disabled'}}</p>
                <p style="margin-bottom: 0px; text-align: center; font-size: 12px;" :style="{color: isActiveTagsAutomation  ? '#409eff !important' : 'gray !important'}">{{isActiveTagsAutomation ? 'Your keywords will be automatically converted to tags.' : 'Your keywords won’t be converted into tags automatically.'}}</p>
                <span v-if="isActiveTagsAutomation" style="top: 0; right: 0; position: absolute; background-color: #409eff; color: white; padding: 0px 8px; font-size: 12px;">
                    Enabled
                </span>
                <span v-else style="top: 0; right: 0; position: absolute; background-color: gray; color: white; padding: 0px 8px; font-size: 12px;">
                    Disabled
                </span>
            </el-card>
        </div>
    </div>
</template>

<script>
import { Card } from 'element-ui'
import SkeletonLoader from '../../SkeletonLoader.vue'

export default {
    props: {
        isLoadingTagsAutomation: Boolean,
        isErrorTagsAutomation: Boolean,
        isActiveTagsAutomation: Boolean,
        onTagsAutomation: Function,
    },
    components: {
        [Card.name] : Card,
        SkeletonLoader
    }
}
</script>