<template>
    <div v-if="isLoadingCustomFieldsBySlug">
        <SkeletonCheckbox />
    </div>
    <div v-else-if="isErrorCustomFieldsBySlug">
        <div class="error-banner" @click="handleDefaultCustomFields" style="cursor: pointer;">
            <p><i class="fas fa-sync-alt" style="margin-right: 8px;"></i> There is an issue with the custom fields. Please refresh or try again later.</p>
        </div>
    </div>
    <div v-else>
        <!-- CUSTOM FIELDS GENERAL -->
        <template v-if="customFieldsBySlug.some(field => field.type === 'general')">
            <el-divider content-position="left">Custom Fields General</el-divider>
            <div :class="computedClassCheckbox">
                <div v-for="(item, index) in customFieldsBySlug.filter(field => field.type === 'general')" :key="index">
                    <el-checkbox
                        :disabled="item.disabled"
                        :label="item.name"
                        :true-label="item.name"
                        :false-label="''"
                        :value="listValueFieldsIntegration.includes(item.name)"
                        @input="handleCheckItemCustomFields(slug, item.name, 'general')"
                        >
                        {{item.name}}
                    </el-checkbox>
                </div>
            </div>
        </template>

        <!-- CUSTOM FIELDS B2B -->
        <template v-if="customFieldsBySlug.some(field => field.type === 'b2b') && !isHideB2b">
            <el-divider content-position="left">Custom Fields B2B</el-divider>
            <div :class="computedClassCheckbox">
                <div v-for="(item, index) in customFieldsBySlug.filter(field => field.type === 'b2b')" :key="index">
                    <el-checkbox
                        :disabled="item.disabled"
                        :label="item.name"
                        :true-label="item.name"
                        :false-label="''"
                        :value="listValueFieldsIntegrationB2b.includes(item.name)"
                        @input="handleCheckItemCustomFields(slug, item.name, 'b2b')"
                        >
                        {{item.name}}
                    </el-checkbox>
                </div>
            </div>
        </template>
        
        <!-- CUSTOM FIELDS ADVANCE -->
        <template v-if="customFieldsBySlug.some(field => field.type === 'advance') && !isHideAdvance">
            <el-divider content-position="left">Custom Fields Enhance Advance</el-divider>
            <div :class="computedClassCheckbox">
                <div v-for="(item, index) in customFieldsBySlug.filter(field => field.type === 'advance')" :key="index">
                    <el-checkbox
                        :disabled="item.disabled"
                        :label="item.name"
                        :true-label="item.name"
                        :false-label="''"
                        :value="listValueFieldsIntegrationAdvance.includes(item.name)"
                        @input="handleCheckItemCustomFields(slug, item.name, 'advance')"
                        >
                        {{item.name}}
                    </el-checkbox>
                    <el-popover
                        :title="item.name"
                        width="220"
                        trigger="hover"
                        placement="right"
                    >
                    
                        <ul style="list-style-type: disc;">
                            <li style="color: #222a42 !important;" v-for="(desc, index) in item.description" :key="index">{{ desc }}</li>
                        </ul>
                        <i slot="reference" class="fa fa-question-circle" style="cursor: pointer; margin-left: 8px;" :style="{color: classCheckbox ? '' : '#409EFF'}"></i>
                    </el-popover>

                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { Divider, Checkbox } from 'element-ui'
import SkeletonCheckbox from './SkeletonCheckbox.vue' 

export default {
    props: {
        slug: String,
        classCheckbox: String,
        isLoadingCustomFieldsBySlug: Boolean,
        isErrorCustomFieldsBySlug: Boolean,
        isHideB2b: Boolean,
        isHideAdvance: Boolean,
        handleDefaultCustomFields: Function,
        customFieldsBySlug: [Array, Object],
        listValueFieldsIntegration: Array,
        listValueFieldsIntegrationB2b: Array,
        listValueFieldsIntegrationAdvance: Array,
        handleCheckItemCustomFields: Function,
    },
    components: {
        [Divider.name]: Divider,
        [Checkbox.name]: Checkbox,
        SkeletonCheckbox,
    },
    computed: {
        computedClassCheckbox(){
            return this.classCheckbox ? this.classCheckbox : 'checkbox-field-integration';
        }
    }
}
</script>